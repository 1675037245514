import React from "react";
import "./App.css";
import Cheryl from "./photos/Cheryl.jpg";

function App() {
  const supervisionSkills = [
    "Music Search",
    "Licensing negotiation",
    "Budgeting",
    "Music clearance",
    "Scene spotting",
    "Quote requests",
    "Cue sheets",
  ];
  const techStack = [
    "React 16.8+",
    "NextJS",
    "Svelte",
    "HTML, CSS",
    "Javascript ES6",
    "Typescript",
    "Tailwind",
    "Antd, MUI components",
    "Solana Web3.js",
    "Solidity",
    "ethers.js",
    "viem",
    "RESTful APIs",
    "GraphQL",
    "Cypress",
    "Figma",
  ];

  return (
    <div className="App">
      <div className="landing-hero">
        <h1>
          <bold>Cheryl Kara</bold> is a{" "}
          <span className="rose">music supervisor</span> and{" "}
          <span className="rose">blockchain developer</span> based between
          Singapore and Reykjavík.
        </h1>
      </div>
      <div className="picture">
        <img src={Cheryl} alt="profile" id="profile-pic" />
      </div>
      <div className="skills">
        <h2 className="text-right">Supervision</h2>
        <div className="skillset">
          {supervisionSkills.map((sx) => {
            return (
              <>
                {sx}
                <br />
              </>
            );
          })}
          <a href="https://www.imdb.com/name/nm12287699/">IMDB</a>
          <br />
        </div>

        <div className="skillset text-right">
          {techStack.map((sx) => {
            return (
              <>
                {sx}
                <br />
              </>
            );
          })}
        </div>
        <h2>Tech stack</h2>

        <h2 className="text-right">Connect</h2>
        <div className="skillset">
          <a
            href="https://www.linkedin.com/in/cherylosophy/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
          <br />
          <a
            href="https://www.instagram.com/cherylosophy/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          <br />
          <a
            href="mailto:cherylkaraa@gmail.com?subject=Hi Cheryl!"
            target="_blank"
            rel="noreferrer"
          >
            Email
          </a>
          <br />
        </div>

        <div className="footer">UTC/GMT+0.</div>
      </div>
    </div>
  );
}

export default App;
